import { notificationStore } from '../store/NotificationStore';
import decompress from './decompressData';

const setNotification = (status, message = null) => {
  notificationStore.setNotification({ ...notificationStore.notification, status, message });
};

export default async function downloadFile(fileData, fileName, fileType = 'application/pdf') {
  try {
    let data = fileData;
    if (fileType === 'application/zip') {
      data = await decompress(fileData);
    }
    const blobObj = new Blob([data], { type: fileType });
    const downloadUrl = window.URL.createObjectURL(blobObj);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = downloadUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    return Promise.resolve();
  } catch (error) {
    console.error('Error during file download:', error);
    return setNotification('error', 'Error occured during file download');
  }
}
