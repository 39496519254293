import zlib from 'zlib';

const decompress = async (compressedData, parseJson = false) => new Promise((resolve, reject) => {
  zlib.gunzip(Buffer.from(compressedData, 'base64'), (err, decompressedBuffer) => {
    if (err) {
      reject(err);
    } else {
      const result = parseJson ? JSON.parse(decompressedBuffer.toString()) : decompressedBuffer.toString();
      resolve(result);
    }
  });
});

export default decompress;
